module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SwipeStation - HALF TIME DRINKS. IN HALF THE TIME.","short_name":"SwipeStation","background_color":"#ffffff","description":"SwipeStation is a contact-free mobile payment service that has proven to eliminate queues in large venues.","theme_color":"#FFBA00","icon":"src/assets/favicon.png","start_url":"/","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a99449b9f1db8e7fa1dc5b6422a0589"},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"e6a7fddd-3d2f-4141-842e-86e71d2f8f90","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
